export enum MailEnum {
    Welcome = 'Bine ai venit',
    New_Order = 'Comanda noua',
    New_Order_Admin = 'Comanda noua admin',
    Payment_Success = 'Plata acceptata',
    Reset_Password = 'Resetare parola',
    Admin_Password = 'Parola Admin',
    Contact = 'Contact',
    New_Product_Request = 'Cerere produs',
    New_Product_Request_Admin = 'Cerere produs admin',
    Price_Alert = 'Alerta pret',
    No_Stock_Alert = 'Alerta lipsa stoc',
    Invoice_Generated = 'Factura generata',
    AWB_Generated = 'AWB generat',
    Order_Note_Alert = 'Alerta nota comanda',
    Newsletter = 'Newsletter',
    New_Order_Provider = 'Comanda noua furnizor',
}

export interface Mail {
    id?: string;
    name: MailEnum;
    description?: string;
    template: string;
    for_admin: boolean;
    distributionListId?: string;
    distributionList?: DistributionList;
}

export interface DistributionList {
    id?: string;
    list: string[];
    mail: Mail[];
}

export enum MailTemplate {
    Welcome = 44,
    New_Order = 1,
    New_Order_Admin = 24,
    Reset_Password = 43,
    Admin_Password = 41,
    Contact = 45,
    New_Product_Request = 47,
    New_Product_Request_Admin = 46,
    Price_Alert = 48,
    Invoice_Generated = 40,
    AWB_Generated = 39,
    Newsletter = 42,
    AWB_In_Progress = 73,
    Order_Shipped = 54,
}
